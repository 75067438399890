@import 'configStyles';

.errorText {
	margin: 0;
	padding: 0;
	list-style: none;
	color: $red;
	font-size: 20px;
	line-height: 1.1667;
	letter-spacing: 0.02rem;
	text-align: center;
}
.loginInner {
	position: relative;
	width: 86rem;
	margin: 10rem auto 4rem;
}

.loginLogo {
	display: block;
	margin: 0rem auto 13.5rem;
	position: relative;
	top: 20px;
	max-width: 40rem;

	&.oxygo {
		max-width: 30.7rem;
	}
}

.login {
	background: url('../../assets/img/login-bg.jpg') center / cover;
	position: relative;
	min-height: 100vh;
	overflow: hidden;
}

.contactAdmin {
	max-width: 36.5rem;
	margin-left: auto;
	margin-right: auto;
	text-align: center;

	& .backBtn {
		position: absolute;
		top: 140px;
		left: 0;
	}

	& p {
		margin-top: 0;
		margin-bottom: 2.4rem;
		color: $dark-2;
		font-size: 2rem;
		line-height: 1.6;
	}
}
