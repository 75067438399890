@import 'configStyles';

.container {
	position: relative;
	width: 100%;
}

.input {
	width: 100%;
	/* border-radius: 0.2rem; */
	border: 0.1rem solid color(black a(24%));
	/* color: #333437; */
	font-size: 1.2rem;
	letter-spacing: 0.02rem;
	/* padding: 0.4rem 1rem 0.2rem; */
	display: flex;
	height: 36px;
	padding: 0px 8px;
	align-items: center;
	gap: 8px;
	border-radius: 4px;
	border: 1px solid var(--border-main, #e0e0e0);

	&:focus {
		// border-color: $violet;
		outline: none;
	}

	&::placeholder {
		color: var(--text-tertiary, #999);
		font-family: Roboto;
		font-size: 14px;
		font-style: italic;
		font-weight: 400;
		line-height: 24px; /* 171.429% */
		letter-spacing: 0.5px;
	}

	&::-ms-clear {
		display: none;
	}
}

.suggestionsContainer {
	display: none;
	position: absolute;
	top: 100%;
	left: 0;
	z-index: 1;
	width: 100%;
	max-height: 32.8rem;
	margin-top: -0.1rem;
	overflow-y: auto;
	background-color: white;
	border: 0.1rem solid white;
	border-radius: 0 0 0.2rem 0.2rem;
}

.suggestionsContainerOpen {
	display: block;
	padding-bottom: 0px;
	flex-direction: column;
	align-items: flex-start;
	border-radius: 0px 0px 4px 4px;
	background: var(--background-white, #fff);
	box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
}

.suggestionsList {
	margin: 0;
	padding: 0.5rem 0;
	list-style: none;
	background-color: white;
}

.suggestion {
	width: 100%;
	cursor: pointer;
	color: var(--text-primary, #26251f);
	font-family: Roboto;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 24px;
	height: 36px;
	display: flex;
	gap: 12px;
	padding: 6px 16px 6px 8px;
}

.suggestionHighlighted {
	background: #e8f6fe;
	line-height: 24px;
	// display: flex;
	// gap: 12px;
}
