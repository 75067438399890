@import 'configStyles';

.page {
	display: flex;
	min-height: 100vh;
}

.content {
	flex-grow: 1;
	padding: 4.8rem 5.2rem;

	@media (--xlg-up) {
		padding-right: 28rem;
	}
}

.pageTitle {
	margin: 0 0 3.6rem;
	color: #26251f;
	font-family: Roboto;
	font-size: 32px;
	font-weight: 700;
	line-height: 38px;
	letter-spacing: 0em;
	text-align: left;
}

.uploadNew {
	position: absolute;
	right: 5rem;
	min-width: 22.4rem !important;
}
.dropdowntest {
	height: 2.8rem;
	padding: 0.1rem 2rem 0.1rem 1rem;
	background: #fff;
	border-radius: 0.2rem;
	border: 0.1rem solid #dfdfdf;
	color: #333437;
	font-size: 1.2rem;
	line-height: 2.4rem;
}
.tabs {
	margin-bottom: 3.2rem;

	& li {
		background-color: $white;
		box-shadow: 0 0.2rem 0.4rem 0 rgba($black, 0.2);
		border-radius: 1.8rem;
		font-size: 1.8rem;
		font-weight: 700;
		letter-spacing: 0.05rem;
		color: $dark-3;
		padding: 0.8rem 2rem;
		margin-right: 1rem;
		min-width: 17.5rem;
		text-align: center;

		&:nth-child(2) {
			min-width: 18.5rem;
		}
	}

	& .activeTab {
		background-color: $light-grey-2;
		box-shadow: inset 0 0.1rem 0.3rem 0 rgba($black, 0.2);
		color: $grey;
		font-weight: normal;
	}
}

.table {
	& td {
		font-family: Roboto;
		font-size: 14px;
		font-weight: 400;
		line-height: 16px;
		text-align: left;
		&:nth-child(3) {
			text-align: center;
		}
	}

	& th,
	& td {
		&:last-child {
			padding-right: 1rem;
		}
	}

	& th {
		height: Fixed (42px) !important ;
		padding: 7px, 32px, 7px, 32px !important;
		border-radius: 8px, 8px, 0px, 0px !important;
		border: 1px !important;
		gap: 72px !important;
		font-family: Roboto !important;
		font-size: 14px !important;
		font-weight: 600 !important;
		line-height: 16px !important;
		letter-spacing: 0em !important;
		color: #26251f;
		border-bottom: 1px solid var(--border-main, #e0e0e0) !important;
		&:nth-child(1) {
			width: 12%;
		}

		&:nth-child(2) {
			width: 12%;
		}

		&:nth-child(3) {
			text-align: center;
			width: 12%;
		}

		&:nth-child(4) {
			width: 15%;
		}

		&:nth-child(5) {
			width: 25%;
		}
	}

	& .latest,
	& .upcoming,
	& .test,
	& .unknown,
	& .history,
	& .draft {
		border-radius: 26px;
		color: #fff;
		display: inline-block;
		background-color: #999;
		font-family: Roboto;
		font-size: 12px;
		font-weight: 600;
		line-height: 14px;
		letter-spacing: 0em;
		padding: 8px;
		text-align: right;
	}

	& .latest {
		background-color: #6ab221;
	}
	& .history {
		background-color: #999999;
	}

	& .upcoming {
		background-color: #c56cdf;
	}

	& .test {
		background-color: #6c7ddf;
	}

	& .draft {
		background-color: #efb815;
	}
	& .unknown {
		background-color: #f47500;
	}
}

.tableTooltip {
	position: fixed;
	color: $dark-2;
	display: flex;
	width: 176px;
	border-radius: 8px;
	background: var(--background-white, #fff);
	box-shadow: 0px 2px 4px 2px rgba(0, 0, 0, 0.1);
	display: flex;
	padding: 12px 16px 4px 16px;
	flex-direction: column;
	align-items: flex-start;
	gap: 4px;
	align-self: stretch;
	margin-top: 50px;

	& h2 {
		color: var(--text-primary, #26251f);
		font-family: Roboto;
		font-size: 14px;
		font-style: normal;
		font-weight: 500;
		line-height: 20px;
		letter-spacing: 0.1px;
	}
	position: fixed;
	color: $dark-2;
	display: flex;
	width: 176px;
	border-radius: 8px;
	background: var(--background-white, #fff);
	box-shadow: 0px 2px 4px 2px rgba(0, 0, 0, 0.1);
	display: flex;
	padding: 12px 16px 4px 16px;
	flex-direction: column;
	align-items: flex-start;
	align-self: stretch;
	margin-top: 50px;

	& h2 {
		color: var(--text-primary, #26251f);
		font-family: Roboto;
		font-size: 14px;
		font-style: normal;
		font-weight: 500;
		line-height: 20px;
		letter-spacing: 0.1px;
	}
}

.deleteToolTip {
	position: absolute;
	color: $dark;
	background-color: $white;
	border-radius: 0.8rem;
	box-shadow: 0 0.2rem 0.6rem rgba($black, 0.3);
	font-size: 1rem;
	letter-spacing: 0.01rem;
	padding: 1.4rem 0.9rem 1.4rem 2rem;
	margin-top: 30px;
	width: 176px;
}
.infoIconTrigger {
	display: block;
	position: absolute;
	top: 194px;
	margin: 0 18rem;
}
.help {
	max-width: 19rem;
	box-sizing: border-box;
	border-radius: 8 px;
	box-shadow: 0px 2px 4px 2px #0000001a !important;

	background-color: #ffffff;
	font-family: Roboto !important;
	font-size: 14px !important;
	font-weight: 400 !important;
	line-height: 20px !important;
	letter-spacing: 0.25px !important;
	text-align: left !important;
	width: 176px !important;
	height: 80px !important;
	padding: 12px, 16px, 4px, 16px !important;
	gap: 4px !important;
	height: 100px !important;
	max-width: 19rem;
	box-sizing: border-box;
	border-radius: 8 px;
	box-shadow: 0px 2px 4px 2px #0000001a !important;
	background-color: #ffffff;
	font-family: Roboto !important;
	font-size: 14px !important;
	font-weight: 400 !important;
	line-height: 20px !important;
	letter-spacing: 0.25px !important;
	text-align: left !important;
	width: 176px !important;
	height: 80px !important;
	padding: 12px, 16px, 4px, 16px !important;
	height: 100px !important;

	&:global {
		&.show {
			opacity: 1;
		}
	}

	& .infoIconHelp {
		float: left;
		margin-right: 1rem;
	}

	& p {
		font-family: Roboto;
		font-size: 1rem;
		font-weight: normal;
		font-style: normal;
		font-stretch: normal;
		line-height: 1.2;
		overflow: hidden;
		margin: 0;
	}
}
.list {
	list-style-position: inside;
	padding-left: 0;
	color: var(--text-primary, #26251f);
	font-family: Roboto;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	letter-spacing: 0.25px;

	& li {
		margin: 0;
	}
}

.more {
	cursor: pointer;
	background-color: transparent;
	border: none;
	color: #6c7ddf;
	font-family: Roboto;
	font-size: 16px;
	font-weight: 400;
	line-height: 24px;
	letter-spacing: 0px;
	text-align: left;
	margin-top: 5px;
	cursor: pointer;
	background-color: transparent;
	border: none;
	color: #6c7ddf;
	font-family: Roboto;
	font-size: 16px;
	font-weight: 400;
	line-height: 24px;
	letter-spacing: 0px;
	text-align: left;
	margin-top: 5px;
}

.modal {
	padding: 5rem 5rem 4rem;
}

// .modalDelete {
// 	padding-top: 4rem;
// 	max-width: 72rem;
// }

.moreDiv {
	padding-right: 8px;
	padding-bottom: 8px;
	align-self: stretch;
	height: 42px;
	width: 100%;
}
.versioning {
	color: var(--text-primary, #26251f);
	font-family: Roboto;
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: 20px;
	letter-spacing: 0.1px;
}
.inactiveMassgae {
	font-family: Roboto;
	font-size: 14px;
	font-weight: 400;
	line-height: 20px;
	letter-spacing: 0.25px;
	text-align: left;
}
.panelSummary {
	width: 832px !important;
	height: 100px !important;
	gap: 12px !important;
	border: solid;
}
.selectfilterStyling {
	min-width: 180px;
	margin-right: 30px;
}
.label {
	border-radius: 26px;
	color: #fff;
	display: inline-block;
	background-color: #999;
	font-family: Roboto;
	font-size: 12px;
	font-weight: 600;
	line-height: 14px;
	letter-spacing: 0em;
	padding: 8px;
	text-align: right;
}

.latest {
	background-color: #6ab221;
}

.history {
	background-color: #999999;
}

.upcoming {
	background-color: #c56cdf;
}

.test {
	background-color: #6c7ddf;
}

.draft {
	background-color: #efb815;
}

.unknown {
	background-color: #f47500;
}
.modal {
	padding: 24px;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	gap: 24px;
	border-radius: 8px;
	background: var(--background-white, #fff);
	top: 2.4rem;
}
.modalDelete {
	width: 52rem;
	padding: 5rem 5rem 4rem;
	padding: 2.4rem !important;
	max-width: 72rem;
}
.modalCreate {
	width: 720px;
	padding: 24px;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	gap: 24px;
	border-radius: 8px;
	background: var(--background-white, #fff);
	top: 2.4rem;
}
.deleteSpacing {
	margin-bottom: 8px;
}

.secondaryHeader {
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.tableStyling {
	margin-top: 32px;
}
