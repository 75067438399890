.modal {
	max-width: 72rem;
	padding: 4rem 2rem;
}

::-webkit-scrollbar {
	width: 8px;
}

::-webkit-scrollbar-thumb {
	background-color: #d9d9d980; /* Customize scrollbar color */
	border-radius: 10px; /* Optional: just make scrollbar corner rounded */
}

::-webkit-scrollbar-track {
	background-color: transparent; /* Customize track background */
	border-radius: 10px;
}
/* For Firefox */
/* Note: These properties might not work in all versions of browsers */
/* You might need to use `-moz-*` prefix for older versions of Firefox */
/* Also, Firefox supports scrollbar-width and scrollbar-color properties */
/* which are not supported by other browsers */
* {
	scrollbar-width: thin;
	scrollbar-color: #d9d9d980 transparent;
}

body::-webkit-scrollbar-thumb {
	background: #d9d9d980;
	border-radius: 16px !important;
}

body::-webkit-scrollbar-track {
	background-color: transparent;
}

body::-webkit-scrollbar {
	width: 16px !important;
}
