@import '../styles/functions.scss';

$white: #fff;
$black: #000;
$dark: #0e0e0e;
$dark-2: #333437;
$dark-3: #26251f;
$grey: #999;
$grey-1: #dfdfdf;
$grey-2: #e5e5e5;
$grey-3: #ccc;
$light-grey-1: #f0f0f0;
$light-grey-2: #f4f4f4;
$light-grey-3: #fafafa;
$light-grey-4: #f0f9ff;
$light-grey-5: #ececec;
$blue: #30adfa;
$light-blue: #6c9fdf;
$light-blue-2: #d9f0ff;
$light-blue-3: #a6bbd6;
$dark-blue: #0b3090;
$dark-blue-2: #0c308f;
$red: #e04829;
$violet: #6c7ddf;
$violet-2: #8290e3;

$violet-grey: #7e87b9;
$green: #6ab221;
$yellow: #efb815;
$orange: #ff8300;
$disable: #ebebeb;
$separators: lighten(black, 88%);
$orange-grey: desaturate($orange, 40%);

$input-focus: $violet;
$input-error: $red;

$btn: $violet;
$btn-hover: lighten($btn, 5%);

$btn-disabled: var(--main-secondary, #999);
$filter-list: $blue;
$btns-types-shadow:
	0 0.4rem 2rem 0 transparentize($dark-blue, 0.8),
	0 0.2rem 0.4rem 0 transparentize($black, 0.8);

@custom-media --md-down only screen and (width < 999px);
@custom-media --md-up only screen and (width >= 1000px);

@custom-media --lg-down only screen and (width < 1359px);
@custom-media --lg-up only screen and (width >= 1360px);

@custom-media --xlg-down only screen and (width < 1419px);
@custom-media --xlg-up only screen and (width >= 1420px);

@custom-media --ie-11 all and (-ms-high-contrast: none);
