@import 'configStyles';

:global {
	& .Toastify {
		& .Toastify__toast-container {
			display: flex !important;
			width: 520px !important;
			padding: 24px 32px !important;
			align-items: center !important;
			gap: 10px;
		}

		& .Toastify__toast {
			background-color: $light-grey-3;
			box-shadow: 0 0.2rem 0.6rem desaturate($black, 40%);
			color: $dark-2;
			font-size: 1.6rem;
			font-family: inherit;
			line-height: 1.25;
			letter-spacing: 0.06rem;
			min-height: auto;
			padding: 1.5rem 3.4rem 1.5rem 3rem;
			margin-bottom: 0;
			margin-top: 1rem;

			&:before {
				content: '';
				position: absolute;
				left: 0;
				top: 0;
				bottom: 0;
				width: 1rem;
			}
		}

		& .Toastify__close-button {
			position: absolute;
			right: 0.8rem;
			top: 1.3rem;
		}
	}
}
.Toastify__toast-body {
	display: flex;
	align-items: center;
	gap: 10px;
}

.Toastify__toast {
	border-radius: 8px !important;
	box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.1) !important;
	color: var(--background-white, #fff) !important;
	font-feature-settings:
		'clig' off,
		'liga' off !important;
	font-family: Roboto !important;
	font-size: 14px !important;
	font-style: normal !important;
	font-weight: 500 !important;
	line-height: normal !important;
	display: flex !important;
	width: 520px !important;
	padding: 24px 32px !important;
	align-items: center !important;
	gap: 10px !important;
}
.Toastify__toast--success {
	background: var(--status-success, #6ab221) !important;
}
.Toastify__toast--error {
	background: var(--status-error, #df0c0c) !important;
}
.Toastify__toast--warning {
	background: var(--status-warning, #efb815) !important;
}
.Toastify__toast--info {
	background: var(--main-primary, #6c7ddf) !important;
}
