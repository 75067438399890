.toggle-button {
	width: 4rem;
	height: 2.4rem;
	border-radius: 45px;
	cursor: pointer;
	position: relative;
	user-select: none;
	flex-shrink: 0;
	display: flex;
	align-items: center;
}

.slider {
	border-radius: 50%;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
	position: absolute;
	transition: transform 0.3s ease;
}

.on {
	background: var(--main-primary, #6c7ddf);
}

.on .slider {
	transform: translateX(19px);
	background: var(--background-white, #fff);
	height: 1.8rem;
	width: 1.8rem;
}

.off {
	background: var(--main-primary, #6c7ddf);
}

.off .slider {
	transform: translateX(2px);
	background: var(--background-white, #fff);
	height: 1.8rem;
	width: 1.8rem;
	margin-left: 1px;
}
